import React from 'react';
import '../../assets/css/m~squared.css';
import pollockBg from '../../assets/imgs/art/pollock-bw-644x351.jpg';
import Layout from '../../components/Layout';
import loadable from '@loadable/component';

const Pollock = loadable(() => import('../../components/pollock/latest'));

const Square = () => (
  <div style={{backgroundImage: `url(${pollockBg})`, backgroundBlendMode: 'lighten'}} className="m-squared-square">
  </div>
);

const PollockSquares = () => (
  <div style={{height: '75vh'}}>
    <div className="m-squared-grid">
      <div className="m-squared-square">
        <Pollock />
      </div>      
      <div className="m-squared-square">
        <Pollock />
      </div>      
      <div className="m-squared-square">
        <Pollock />
      </div>
      <div className="m-squared-square">
        <Pollock />
      </div>
      <div className="m-squared-square">
        <Pollock />
      </div>  
      <div className="m-squared-square">
        <Pollock />
      </div>      
      <div className="m-squared-square">
        <Pollock />
      </div>
      <div className="m-squared-square">
        <Pollock />
      </div>
      <div className="m-squared-square">
        <Pollock />
      </div>
    </div>
  </div>
);

const PollockLayout = ({location}) => 
  <Layout location={location}>
    <PollockSquares />
  </Layout>;

export default PollockLayout;